import React from 'react';

const TnC = () => {
    return (
        <div style={{ width: "90%", margin: "0px auto" }}>

            <h1>AVENUE GROUP VENTURES INC.</h1>
            {/* Terms */}
            <h2>TERMS OF USE</h2>

            <p>BEFORE YOU CHECK THE BOX AND CLICK ON THE "I ACCEPT" OR "I AGREE" BUTTON, CAREFULLY READ THE TERMS AND CONDITIONS OF THIS AGREEMENT. BY CLICKING ON THE "I ACCEPT" OR "I AGREE" BUTTON OR USING OUR PLATFORM IN ANY MANNER, YOU ARE AGREEING TO BE BOUND BY AND ARE BECOMING A PARTY TO THIS AGREEMENT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, PLEASE DO NOT USE OUR SERVICES. WE RESERVE THE RIGHT TO CHANGE THE TERMS OF THIS AGREEMENT IN THE FUTURE AND ANY CHANGES WILL APPLY TO YOUR USE OF THE WEBSITE AFTER THE DATE OF SUCH CHANGE. AS USED IN THIS AGREEMENT "SITE" REFERS TO OUR WEBSITE AT HTTPS://APP.CROWDWAVE.AI.</p>

            <h3>1. ACCEPTANCE OF TERMS</h3>

            <p>AVENUE GROUP VENTURES Inc., a New York corporation (hereinafter referred to as "we", "our" or "us"), provides the Site, and all related software, webpages, information (including User Accounts), documents, communications, files, audio, images/videos, text, graphics, software, software applications, products and services available through the Site (collectively, the "Materials") and all services operated by us and third parties through the Site (collectively, the "Services"), available for your use subject to the terms and conditions set forth in this document and any changes to this document that we may publish from time to time (collectively, the "Terms of Use" or "Agreement").</p>

            <p>We reserve the right to change the Terms of Use and other guidelines or rules from time to time at our sole discretion. Your continued use of the Site, or any Materials or Services accessible through them, after such notice has been posted constitutes your acceptance of the changes. Your use of the Site will be subject to the most current version of the Terms of Use, rules and guidelines. If you breach any of the Terms of Use, your authorization to use the Site automatically terminates.</p>

            <p>We further reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Materials and/or Services (or any part thereof) with or without notice. You agree that, unless we have agreed otherwise in writing, we shall not be liable to you or to any third party for any modification, suspension or discontinuance of our Site, Materials and/or Services (or any part thereof), even if your content is thereby deleted.</p>

            <h3>2. USER ACCOUNTS</h3>
            <p>In order to access or to make full use of the Site, Materials and/or Services you will need to register and create a user account (an "Account").</p>

            <p>By using the Site or setting up an Account you agree:<br />
            that you will not use an Account that is created by another user;<br />
            that you will supply only complete and accurate information in registering for an Account and setting up a profile;<br />
            that you are solely responsible for the activity that occurs on and through your Account, that you will keep your Account password secure at all times, and that we will not be liable for your losses caused by any unauthorized use of your Account; and<br />
            that if your Account has been compromised for any reason, you will immediately notify us of the security breach or unauthorized use of your Account.<br />
            We reserve the right to (a) take appropriate legal action against anyone who violates these Terms of Use; (b) in our sole discretion without notice and without limitation or liability, refuse, restrict access to or availability of, or terminate any user's Account for any reason, even if the deletion of a user's Account will result in deletion of the user's Account content.</p>

            <h3>3. INTELLECTUAL PROPERTY; LIMITED LICENSE; CONFIDENTIALITY</h3>
            <p>The Materials and Services on the Site, as well as their selection and arrangement, are protected by copyright, trademark, patent, and/or other intellectual property laws, and any unauthorized use of the Materials or Services on the Site may violate such laws and these Terms of Use. Except as expressly provided herein, we do not grant any express or implied rights to use the Materials and Services. You agree not to copy, republish, frame, download, transmit, modify, assign, distribute, record or create derivative works based on the Site, their Materials, or their Services or their selection and arrangement, except as authorized herein. In addition, you agree not to use any data mining, robots, or similar data gathering and extraction methods in connection with the Site.</p>

            <p>We recognize the sensitive and confidential nature of the information you provide through your use of our Site, including interactions with our AI platform. While we are committed to maintaining the confidentiality of such user inputs, we may share your inputs with OpenAI and potentially other future service providers through standard APIs to facilitate the services provided. We do not share your data with any other external parties, except as explicitly agreed upon by you or as required by law. All user inputs are treated as confidential information, and we implement industry-standard measures to protect against unauthorized access, use, alteration, or destruction of such information. </p>

            <p>While we uphold the utmost confidentiality of your inputs, we reserve the right to utilize this information internally to enhance the functionality and performance of our Site. This includes analyzing user interactions and queries to develop better features, refine our AI algorithms, and ensure a more effective and personalized user experience. Externally, user inputs may be shared with service providers like OpenAI, strictly for the purpose of providing our services to you.</p>

            <h3>4. TRADEMARK INFORMATION</h3>
            <p>The trademarks, logos, and service marks, including the CROWDWAVE trademark (the "Mark") displayed on the Site belongs to us. You are not permitted to use the Mark without our prior written consent. </p>

            <h3>5. WARRANTIES AND DISCLAIMERS</h3>
            <p>EXCEPT AS EXPRESSLY PROVIDED OTHERWISE IN A WRITTEN AGREEMENT BETWEEN YOU AND US, THE SITE, AND ALL MATERIALS AND SERVICES ACCESSIBLE THROUGH THEM ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (I) THE SERVICES AND MATERIALS WILL MEET YOUR REQUIREMENTS; (II) THE SERVICES AND MATERIALS WILL BE UNINTERRUPTED, TIMELY, SECURE, ACCURATE, UPDATED OR ERROR-FREE; (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICES OR MATERIALS WILL BE EFFECTIVE, ACCURATE, OR RELIABLE; AND (IV) ANY ERRORS IN THE SERVICES OR MATERIALS, OR ANY DEFECTS IN THE SITE, THEIR SERVICES OR MATERIALS, WILL BE CORRECTED.</p>

            <p>The use of the Services or the downloading or other use of any Materials through the Site is done at your own discretion and risk and with your agreement that you will be solely responsible for any damage to your system, loss of data, or other harm that results from such activities. We assume no liability for any virus or other similar software code that is downloaded to your computer or mobile device from the Site or in connection with any Services or Materials offered through the Site. No information, whether oral or written, obtained by you from us or through or from the Site shall create any warranty not expressly stated in these Terms of Use.</p>

            <h3>6. LIMITATION OF LIABILITY</h3>
            <p>IN NO EVENT SHALL AVENUE GROUP VENTURES INC. OR ITS SHAREHOLDERS, OFFICERS, DIRECTORS, EMPLOYEES OR ATTORNEYS BE LIABLE TO YOU OR ANY THIRD PARTY UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR UNDER LEGAL OR EQUITABLE THEORY FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT DAMAGES ARISING FROM YOUR USE OF THE SITE OR ANY OF THE MATERIALS OR SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO AMOUNT ACTUALLY PAID, IF ANY, BY YOU TO US DURING THE 12-MONTH PERIOD PRIOR TO THE EVENT GIVING RISE TO THE CLAIM.</p>

            <h3>7. PRIVACY</h3>
            <p>We improve our products and advertising by using Microsoft Clarity to see how you use our website. By using our site, you agree that we and Microsoft can collect and use this data. Our privacy policy contains more information about our use of Microsoft Clarity as well as privacy terms applicable to collected information. </p>

            <h3>8. INDEMNITY AND LIABILITY</h3>
            <p>You agree to indemnify and hold Avenue Group Ventures Inc., and its subsidiaries, affiliates, officers, directors, agents, attorneys, partners and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of: (a) your use of the Site or App, (b) torts or damaged (whether physical or otherwise) caused by you, (c) your violation of these Terms of Use, (d) your violation of any rights of another person or entity, or (e) your violation of any applicable law, rule or regulation.</p>

            <h3>9. GOVERNING LAW, JURISDICTION</h3>
            <p>This Agreement is governed in all respects by the laws of the State of New York without regard to conflict of laws provisions. SUBJECT TO THE REQUIREMENT TO SUBMIT TO ARBITRATION, BY ACCEPTING THIS AGREEMENT AND USING THE SITE OR APP, YOU ACCEPT AND CONSENT TO THE PERSONAL JURISDICTION AND VENUE OF THE FEDERAL AND STATE COURTS IN NEW YORK COUNTY, NEW YORK. THE PARTIES HEREBY WAIVE THEIR RIGHT TO A TRIAL BY JURY OF ANY CLAIMS OR CONTROVERSIES ARISING OUT OF THIS AGREEMENT OR THE USER'S USE OF THE SITE OR THE SERVICES PROVIDED BY OR THROUGH AVENUE GROUP VENTURES INC. IN ADDITION, ANY CONTROVERSY OR CLAIM SHALL BE LITIGATED ON AN INDIVIDUAL BASIS, AND SHALL NOT BE CONSOLIDATED WITH ANY CLAIM OR CONTROVERSY OF ANY OTHER PARTY (INCLUDING, BUT NOT LIMITED TO, BY MEANS OF CLASS ACTION LAW SUITS).</p>

            <p>NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS AGREEMENT, YOU AGREE THAT ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO YOUR USE OF THE SITE OR APP, THIS AGREEMENT OR THE BREACH, TERMINATION, ENFORCEMENT, INTERPRETATION OR VALIDITY THEREOF (COLLECTIVELY, "DISPUTES") WILL BE SETTLED BY BINDING ARBITRATION BETWEEN YOU AND HL, EXCEPT THAT EACH PARTY RETAINS THE RIGHT TO SEEK INJUNCTIVE OR OTHER EQUITABLE RELIEF IN A COURT OF COMPETENT JURISDICTION TO PREVENT THE ACTUAL OR THREATENED INFRINGEMENT, MISAPPROPRIATION OR VIOLATION OF A PARTY'S COPYRIGHTS, TRADEMARKS, TRADE SECRETS, PATENTS OR OTHER INTELLECTUAL PROPERTY RIGHTS. AS NOTED ABOVE, YOU ACKNOWLEDGE AND AGREE THAT YOU AND HL ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE AS A PLAINTIFF OR CLASS IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING. FURTHER, UNLESS BOTH YOU AND HL OTHERWISE AGREE IN WRITING, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF ANY CLASS OR REPRESENTATIVE PROCEEDING. THIS "DISPUTE RESOLUTION" SECTION WILL SURVIVE ANY TERMINATION OF THESE TERMS. THE ARBITRATION WILL BE ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION ("AAA") IN ACCORDANCE WITH THE COMMERCIAL ARBITRATION RULES (THE "AAA RULES") THEN IN EFFECT. THE ARBITRATION PROCEEDINGS SHALL TAKE PLACE IN NEW YORK, NEW YORK.</p>

            <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE SITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES OTHERWISE SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>

            <h3>10. GENERAL</h3>
            <p>The Terms of Use and the other rules, guidelines, licenses and disclaimers accessible through the Site constitute the entire agreement between Avenue Group Ventures Inc. and you with respect to your use of the Site and App. If for any reason a court of competent jurisdiction finds any provision of these Terms of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to effect the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect. Any failure by Avenue Group Ventures Inc. to enforce or exercise any provision of these Terms of Use or related right shall not constitute a waiver of that right or provision.</p>

            <p>Last Updated: February 14, 2025</p>
            {/* Privacy policy */}
            <h2>PRIVACY POLICY</h2>

            <p>This Privacy Policy discloses the privacy practices for our website at HTTPS://APP.CROWDWAVE.AI (the "Site"), through which we offer an AI-Powered Audience Simulator software application operated by Avenue Group Ventures Inc.</p>

            <p>We reserve the right to change this Privacy Policy at any time. Such changes, modifications, additions or deletions shall be effective immediately upon notice thereof, which may be given by means including, but not limited to issuing an e-mail to the e-mail address listed by registered users or posting the revised Policy on this page or elsewhere on the Site. You acknowledge and agree that it is your responsibility to maintain a valid e-mail address as a registered user, review this site and this Policy periodically and to be aware of any modifications. Your continued use of the Site after such modifications will constitute your: (a) acknowledgment of the modified Policy; and (b) agreement to abide and be bound by the modified Policy.</p>

            <h3>1. What information do we collect from you?</h3>

            <p>In order to better provide you with our services, we collect two types of information about our users: Personally Identifiable Information and Non-Personally Identifiable Information. Our primary goal in collecting information from you is to provide you with a smooth, efficient, and customized experience while using our Site.</p>
            <p><strong>Personally Identifiable Information:</strong>&nbsp; This refers to specific information about you and your organization. This information may include without limitation your first and last name, email address, physical address, and your payment or bank account information, if applicable.</p>
            <p><strong>Non-Personally Identifiable Information:</strong>&nbsp; This refers to information that does not by itself identify a specific individual or organization. This information is compiled and analyzed on both a personal and an aggregated basis.</p>
            <h3>2. Where do we collect information from you and how do we use it?</h3>
            <p>We do not collect any Personally Identifiable Information about you or your organization unless you voluntarily provide it to us. You provide certain Personally Identifiable Information to us when you register for a user account/profile to utilize our services, for example.</p>
            <p>In addition, we may also collect, or our third party ad server and/or content server may collect, certain Non-Personally Identifiable Information to diagnose problems with our systems/software and to gather demographic information.</p>
            <p>We will use your Personally Identifiable Information to provide our services to you. For example, we will use your information to create your user account and enable you to utilize the many features we make available to subscribers of our platform. We will also use Personally Identifiable Information to enhance the operation of our Site and software applications, improve our marketing and promotional efforts, statistically analyze the Site use, improve our product and service offerings, and customize our Site's content, layout, and services. We may use Personally Identifiable Information to deliver information to you and to contact you regarding administrative notices and regarding product or service offerings which we believe may be of interest to you. You will be given an opportunity to opt out of receiving promotional materials from us. We may also use Personally Identifiable Information to troubleshoot problems and enforce our agreements with you. While you may opt-out of receiving our promotional communications by taking the measures outlined in such communications, you may not opt-out of receiving administrative communications. </p>
            <p>We partner with Microsoft Clarity and Microsoft Advertising to capture how you use and interact with our website through behavioral metrics, heatmaps, and session replay to improve and market our products/services. Website usage data is captured using first and third-party cookies and other tracking technologies to determine the popularity of products/services and online activity. Additionally, we use this information for site optimization, fraud/security purposes, and advertising. For more information about how Microsoft collects and uses your data, visit the <a href="https://www.microsoft.com/privacy/privacystatement" target="_blank">Microsoft Privacy Statement</a>.</p>
            <h3>3. With whom do we share your information?</h3>
            <p>We do not sell, trade, or rent your Personally Identifiable Information to others. We may utilize the services of third parties and provide some of our services through contractual arrangements with affiliates, services providers, partners and other third parties, and to facilitate such arrangements we reserve the right to share with such third parties Personally Identifiable Information, provided that such third party agree to not share the information. Notwithstanding anything to the contrary, we reserve the right to utilize your information to contact you about products or services we believe may be of interest to you.</p>
            <p>We recognize the sensitive and confidential nature of the information you provide through your use of the Site, including interactions with our AI platform. While we are committed to maintaining the confidentiality of such user inputs, we may share user inputs with OpenAI and potentially other future service providers through standard APIs to facilitate the services provided. We do not share your data with any other external parties, except as explicitly agreed upon by you or as required by law. All user inputs are treated as confidential information, and we implement industry-standard measures to protect against unauthorized access, use, alteration, or destruction of such information. </p>
            <p>While we uphold the utmost confidentiality of your data inputs, we reserve the right to utilize this information internally to enhance the functionality and performance of the Site and the software featured on it. This includes analyzing user interactions and queries to develop better features, refine AI algorithms, and ensure a more effective and personalized user experience.</p>
            <p>Occasionally we may be required by law enforcement or judicial authorities to provide Personally Identifiable Information to the appropriate governmental authorities. We will disclose Personally Identifiable Information upon receipt of a court order, subpoena, or to cooperate with a law enforcement investigation, or if we reasonably believe that we are required to do so by law. We fully cooperate with law enforcement agencies in identifying those who use our services for illegal activities. We reserve the right to report to law enforcement agencies any activities that we in good faith believe to be unlawful.</p>
            <p>We may also provide Non-Personally Identifiable Information about our customers' traffic patterns, and related site information to third party advertisers, but these statistics do not include Personally Identifiable Information.</p>
            <h3>4. How can you update or correct your Personally Identifiable Information?</h3>
            <p>We believe you should have the ability to access and edit the Personally Identifiable Information that you have provided to us. You may change any of your Personally Identifiable Information in your account at any time by logging on to your account and updating your profile/information.</p>
            <p>We encourage you to promptly update your Personally Identifiable Information if it changes. You may ask to have the information on your account deleted or removed; however, because we keep track of past transactions, you cannot delete information associated with past transactions on the Site/App. In addition, it may be impossible to completely delete your information without some residual information because of backups.</p>
            <h3>5. What are your choices regarding collection, use, and distribution of your information?</h3>
            <p>We may occasionally send you direct mail (including e-mail) about products and services that we feel may be of interest to you. You will have the ability to opt out of receiving such communications from us by following the instructions in such communications. </p>
            <h3>6. What security precautions are in place to protect against the loss, misuse, or alteration of your information?</h3>
            <p>Your Personally Identifiable Information is secure on the Site, consistent with current industry standards. The importance of security for all Personally Identifiable Information associated with our users is of utmost concern to us. Your Personally Identifiable Information is protected in several ways. Access by you to your Personally Identifiable Information is available through a password and unique customer ID selected by you. This password is encrypted. We recommend that you do not divulge your password to anyone. In addition, your Personally Identifiable Information resides on a secure server that only our selected personnel and contractors have access to via password. We encrypt your Personally Identifiable Information to prevent unauthorized parties from viewing such information when it is transmitted to us. </p>
            <p>Unfortunately, however, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your Personally Identifiable Information, you acknowledge that: (a) there are security and privacy limitations of the Internet which are beyond our control; (b) the security, integrity and privacy of any and all information and data exchanged between you and us through the Site cannot be guaranteed; and (c) any such information and data may be viewed or tampered with in transit by a third party. Accordingly, and notwithstanding anything to the contrary, while our systems utilize industry-standard security measures, we will not be liable for security or data breaches that circumvent such security measures.  </p>

            <h3>7. Do we use "cookies"?</h3>

            <p>When you use our Site we will store cookies on your computer/device in order to facilitate and customize your use of our Site/App. A cookie is a small data text file, which a website stores on your device's hard drive that can later be retrieved to identify you to us. Our cookies store randomly assigned user identification numbers, the country where you are located, and other information relating to your use of our Site. The cookies make your use of the Site easier, make them run more smoothly and help us to maintain a secure Site. You are always free to decline our cookies if your browser permits, but some parts of our Site may not work properly in that case.</p>

            <p>If you have any questions about this Privacy Policy, the practices of this site, or your dealings with this site, please contact us by sending us an email to <a href="mailto:contact@crowdwave.ai">contact@crowdwave.ai</a></p>

            <p>This Privacy Policy is effective as of February 14, 2025</p>
            {/* Trial agreement */}
            <h2>AI-Powered Audience Simulator Trial</h2>
            <h2>Software as a Service Agreement</h2>
            <p>This Software as a Service Agreement (this "Agreement"), effective as of June 1, 2024 (the "Effective Date"), is by and between Avenue Group Ventures Inc., a New York corporation ("Provider") and you ("Customer"). </p>
            <ol>
                <li><p>Background. This Agreement facilitates a beta testing phase for Provider's software, which is provided to the Customer without charge, subject to the terms of this Agreement. By entering into this Agreement, Customer acknowledges that the software is in beta form and may contain defects or deficiencies which are being tested under real-world conditions. In exchange for the opportunity to use the software at no cost, Customer agrees to actively utilize the software and provide timely, detailed, and constructive feedback to Provider. This feedback is crucial for Provider to enhance the software's functionality, usability, and overall performance prior to its official release. All feedback provided, and ideas generated from such feedback, will be property of Provider.</p></li>
                <li><p>Expectations of Customer.  Specifically, Provider has the following expectations of Customer:</p>
                    <ol>
                        <li><p>Participate in meetings:  Kickoff (1hr), Mid-point Check-in (1hr), and Final Review (1hr)</p></li>
                        <li><p>Minimum usage:  Participants are expected to simulate at least five (5) surveys over the course of the program</p></li>
                        <li><p>Human vs Simulator Comparisons:  Customer agrees to bring at least two (2) human studies to run comparisons against using the simulator and share the results with Provider</p></li>
                        <li><p>Testimonial:  It is Provider's hope that upon completing the beta testing phase Customer provide a testimonial which, with Customer's consent, Provider can use for promotional purposes</p></li>
                        <li><p>Case Study:  Provider intends to develop case studies for promotional purposes.  It is Provider's hope that upon completing the beta testing phase Customer collaborate with Provider to document a case study which, with Customer's consent, can be used for promotional purposes.</p></li>
                    </ol>
                </li>
                <li><p>Provision of Access. Subject to the terms and conditions of this Agreement, Provider hereby grants Customer a non-exclusive, non-transferable right to access and use the Services during the Trial Term, solely for use by authorized users in accordance with the terms and conditions herein. Such use is limited to Customer's internal use. For purposes of this Agreement, "Services" and "Software" mean the AI-Powered Audience Simulator software and associated services.</p></li>
                <li><p>Use Restrictions. Customer shall not at any time, directly or indirectly, and shall not permit any users to: (i) copy, modify, or create derivative works of the Services, in whole or in part; (ii) rent, lease, lend, sell, license, sublicense, assign, distribute, publish, transfer, or otherwise make available the Services; or (iii) remove any proprietary notices from the Services.</p></li>
                <li><p>Reservation of Rights. Provider reserves all rights not expressly granted to Customer in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement, nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third party any intellectual property rights or other right, title, or interest in or to the Provider IP.</p></li>
                <li><p>Trial Period - No Fees. No service fees will apply during the Trial Period.</p></li>
                <li><p>Term & Termination. The term of this Agreement shall commence on the Effective Date and shall continue until terminated by either party in accordance with this Agreement (the "Trial Period"). Notwithstanding anything to the contrary in this Agreement, either party may terminate this Agreement at any time and for any reason upon notice to the other party (which notice may be given by email or any other electronic form).</p></li>
                <li>
                    <p>Confidentiality. Under the terms of this Agreement, Customer acknowledges and agrees that all information pertaining to Provider's software, including but not limited to the access to and operation of the software, as well as all communications and feedback provided to Provider, are confidential ("Confidential Information"). Customer agrees to maintain the confidentiality of all such information and to not disclose any part of it to any third party without the prior written consent of Provider. This confidentiality obligation includes, but is not limited to, all findings, data, strategic information, and feedback resulting from the use of the software. Customer further agrees to take all reasonable precautions to prevent any unauthorized use, disclosure, publication, or dissemination of Confidential Information.</p>
                    <p>We recognize the sensitive and confidential nature of the information Customer provides through its use of the Software, including interactions with Provider's AI platform. While Provider is committed to maintaining the confidentiality of such user inputs, Provider may share user inputs with OpenAI and potentially other future service providers through standard APIs to facilitate the services provided. Provider does not share Customers' data with any other external parties, except as explicitly agreed upon by Customer or as required by law. All user inputs are treated as confidential information, and Provider implements industry-standard measures to protect against unauthorized access, use, alteration, or destruction of such information.</p>
                    <p>While Provider upholds the utmost confidentiality of Customer inputs, Provider reserve the right to utilize this information internally to enhance the functionality and performance of its Software. This includes analyzing user interactions and queries to develop better features, refine AI algorithms, and ensure a more effective and personalized user experience.</p>
                </li>
                <li><p>Disclaimers. THE SERVICES ARE PROVIDED "AS IS" AND PROVIDER HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE. PROVIDER SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR RESULTS OF THE USE THEREOF, WILL MEET CUSTOMER'S OR ANY OTHER PERSON'S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE, ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. WITHOUT LIMITING THE FOREGOING, CUSTOMER UNDERSTANDS THAT THE SERVICES ARE NOT INTENDED TO REPLACE INDEPENDENT MONITORING AND REVIEW OF CALL TRANSCRIPTS TO ENSURE COMPLIANCE WITH APPLICABLE LAWS AND REGULATIONS. THE SERVICES ARE MEANT MERELY AS A TOOL AND NOT AS A REPLACEMENTS OF OTHER MEASURES NECESSARY TO ENSURE COMPLIANCE.</p></li>
                <li><p>Limitations of Liability. IN NO EVENT WILL PROVIDER BE LIABLE UNDER OR IN CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, DIRECT, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION IN VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF GOODWILL OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY, OR RECOVERY OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF REPLACEMENT GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER PROVIDER WAS ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE OTHERWISE FORESEEABLE. IN NO EVENT WILL PROVIDER'S AGGREGATE LIABILITY ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE SOFTWARE UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, EXCEED THE GREATER OF (i) THE TOTAL AMOUNT PAID TO PROVIDER UNDER THIS AGREEMENT IN THE 30 DAY PERIOD PRECEDING THE EVENT GIVING RISE TO THE CLAIM AND (ii) TEN DOLLARS.</p></li>
                <li><p>Miscellaneous. This Agreement constitutes the sole and entire agreement of the parties with respect to the Services accessed during the Trail Period. This Agreement is governed by and construed in accordance with the internal laws of the State of New York without giving effect to any choice or conflict of law provision or rule that would require or permit the application of the laws of any jurisdiction other than those of the State of New York. Any legal suit, action, or proceeding arising out of or related to this Agreement or the licenses granted hereunder will be instituted exclusively in the federal courts of the United States or the courts of the State of New York in each case located in the County of New York. Customer may not assign any of its rights or delegate any of its obligations hereunder.</p></li>
                <li><p>Counterparts; Litigation Costs. This Agreement may be executed in counterparts, each of which is deemed an original, but all of which together are deemed to be one and the same agreement. If either party institutes any legal suit, action, or proceeding against the other party to enforce this Agreement (or obtain any other remedy in respect of any breach of this Agreement), the prevailing party in the suit, action or proceeding shall be entitled to receive, in addition to all other damages to which it may be entitled, the costs incurred by such party in conducting the suit, action, or proceeding, including reasonable attorneys' fees and expenses and court costs.</p></li>
            </ol>

        </div>
    );
};

export default TnC;