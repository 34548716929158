import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../AuthProvider';

import DynamicTable from '../dynamic/DynamicTable';
import LinearProgress from '@mui/material/LinearProgress';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Loading from '../ui/Loading';

const DeleteDialog = React.memo(({
  api,
  surveyList,
  setSurveyList,
  mergedSurveyList,
  setMergedSurveyList,
  deleteParams,
  setDeleteParams,
  deleteDialogOpen,
  setDeleteDialogOpen,
  deletedSurveys
}) => {
  const deleteSurvey = async (params) => {
    const response = await api.delete(`/surveys/${params.row.survey_id}`);
    if (response && response.status !== 200) {
        console.log('error deleting survey');
    } else {
        deletedSurveys.current.add(params.row.survey_id);
        process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'Delete');
        console.log('survey deleted');
        console.log(response);
    }
    setSurveyList(surveyList.filter(survey => survey.survey_id !== params.row.survey_id));
    // Filter out deleted surveys when updating state
    setMergedSurveyList(mergedSurveyList.filter(survey => !deletedSurveys.current.has(survey.survey_id)));
    setDeleteParams(null);
    setDeleteDialogOpen(false);
  }

  const closeDelete = () => {
    setDeleteDialogOpen(false);
  };
  return (
      <Dialog
        open={deleteDialogOpen}
        onClose={closeDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to delete this survey?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This action can't be undone. You will lose access to your survey and all associated data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {closeDelete()}} autoFocus>Cancel</Button>
          <Button color="error" onClick={() => {deleteSurvey(deleteParams)}}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
  );
});

const SurveyList = () => {
    const [surveyList, setSurveyList] = useState([]);
    const [pinnedRows, setPinnedRows] = useState({ top: [], bottom: [] });
    const [pinnedRowIds, setPinnedRowIds] = useState([]);
    const [unpinnedRows, setUnpinnedRows] = useState([]);
    const [mergedSurveyList, setMergedSurveyList] = useState([]);
    const [firstMergeSuccessful, setFirstMergeSuccessful] = useState(false);
    const api = useApi();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteParams, setDeleteParams] = useState(null);
    const deletedSurveys = React.useRef(new Set());
    const copiedSurveys = React.useRef(new Set());
    const [copiedSurvey, setCopiedSurvey] = useState(null);
    const [hasNewSurvey, setHasNewSurvey] = useState(false);// detects recently copied surveys

    const openDelete = (params) => {
      setDeleteParams(params);
      setDeleteDialogOpen(true);
    };

    useEffect(() => {
        async function getUser() {
            const userResponse = await api.get('/users/me');
            if (userResponse && userResponse.status === 200) {
                process.env.REACT_APP_ENV === 'staging' && console.log('User fetched successfully');
                process.env.REACT_APP_ENV === 'staging' && console.log('User:', userResponse.data);
                setUser(userResponse.data);
            } else {
                console.error('Error fetching user:', userResponse);
            }
        }
        async function fetchSurveys() {
            try {
                // get all surveys for user
                const surveysResponse = await api.get(`/surveys/`);
                if (surveysResponse && surveysResponse.status === 200) {
                    process.env.REACT_APP_ENV === 'staging' && console.log('Surveys fetched successfully');
                    process.env.REACT_APP_ENV === 'staging' && console.log('Survey list:', surveysResponse.data);
                    setSurveyList(surveysResponse.data);
                    // get all runs for a user
                    const surveyRunsResponse = await api.get(`/surveys/runs/`);
                    if (surveyRunsResponse && surveyRunsResponse.status === 200) {
                        process.env.REACT_APP_ENV === 'staging' && console.log('Survey runs fetched successfully');
                        process.env.REACT_APP_ENV === 'staging' && console.log('Survey runs:', surveyRunsResponse.data);
                        // merge survey runs into survey list
                        setMergedSurveyList(surveysResponse.data.map(survey => {
                            survey.runs = surveyRunsResponse.data.filter(run => run.survey_id === survey.survey_id);
                            if (survey.runs.length === 0) {
                                survey.status = 'draft';
                            } else {
                                survey.status = survey.runs[0].status;
                            }
                            return survey;
                        }));
                    } else {
                        console.error('Error fetching survey runs:', surveyRunsResponse);
                    }
                }
                // DEBUG: prints the details for last X surveys in the console
                // for (let i = 0; i < 5; i++) {
                //     const debugResponse = await api.get(`/surveys/${surveysResponse.data[i].survey_id}`);
                //     process.env.REACT_APP_ENV === 'staging' && console.log('Survey details:', debugResponse.data);
                // }
            } catch (error) {
                console.error('Error fetching surveys:', error);
            }
        }
        if (hasNewSurvey) {
          fetchSurveys();
          setHasNewSurvey(false);
        }
        if (api) {
          fetchSurveys();
        }
        if (api) getUser();
    }, [api, hasNewSurvey]);

    useEffect(() => {
      process.env.REACT_APP_ENV === 'staging' && console.log('Merged survey list changed:', mergedSurveyList);
      // if we haven't merged yet, do so and perform first status check
      if (mergedSurveyList.length > 0 && !firstMergeSuccessful) {
          mergedSurveyList.forEach(survey => {
              if (survey.status === 'pending' || survey.status === 'running') {
                  console.log('Checking status for:', survey);
                  checkStatus(survey.survey_id, survey.runs[0].survey_run_id);
              }
              if (survey.is_pinned) {
                // remove the survey from merged survey list
                setMergedSurveyList(mergedSurveyList.filter(row => row.survey_id !== survey.survey_id));
              }
          });
          setFirstMergeSuccessful(true);
      } else {
        process.env.REACT_APP_ENV === 'staging' && console.log('nothing to merge');
        // do nothing, checkStatus handles the reset interval
      }
      // split mergedSurveyList into pinned and unpinned rows
      setUnpinnedRows(mergedSurveyList.filter(row => !row.is_pinned));
      mergedSurveyList.forEach(survey => {
        if (survey.is_pinned && pinnedRows.top.filter(row => row.survey_id === survey.survey_id).length === 0 && !pinnedRowIds.includes(survey.survey_id)) {
          setPinnedRows(prev => ({ top: [...prev.top, {id: survey.survey_id, ...survey}], bottom: [] }));
          setPinnedRowIds(prev => ([...prev, survey.survey_id]));
        }
      });
    }, [mergedSurveyList]);

    let interval;
    const checkStatus = async (surveyId, surveyRunId) => {
        const response = await api.get(`/surveys/runs/${surveyRunId}/status`);
        if (response && response.status === 200) {
            process.env.REACT_APP_ENV === 'staging' && console.log('Survey status:', response.data);
            // if the status is complete or error, stop checking
            if (response.data.status === 'complete' || response.data.status === 'error') {
                clearInterval(mergedSurveyList.find(survey => survey.survey_id === surveyId).interval);
                mergedSurveyList.find(survey => survey.survey_id === surveyId).interval = null;
            } else {
                // it's a pending or running status, so clear any active intervals and set a new one
                if (mergedSurveyList.find(survey => survey.survey_id === surveyId).interval) {
                    clearInterval(mergedSurveyList.find(survey => survey.survey_id === surveyId).interval);
                    mergedSurveyList.find(survey => survey.survey_id === surveyId).interval = null;
                }
                // set the new interval
                interval = setInterval(async () => {
                    await checkStatus(surveyId, surveyRunId);
                }, 5000);
                // set the response to the survey.statusData
                mergedSurveyList.find(survey => survey.survey_id === surveyId).statusData = response.data;
                // save the interval itself to the survey as an object for later clearing
                mergedSurveyList.find(survey => survey.survey_id === surveyId).interval = interval;
            }
            // update the status of the survey
            const updatedSurveyList = mergedSurveyList.map(survey => {
                if (survey.survey_id === surveyId) {
                    survey.status = response.data.status;
                }
                return survey;
            });
            setMergedSurveyList(updatedSurveyList.filter(survey => !deletedSurveys.current.has(survey.survey_id)));
            // if a recently copied survey exists, add it to the merged list
            if (copiedSurveys.current.size > 0) {
              setMergedSurveyList([...copiedSurveys.current, ...mergedSurveyList]);
            }
          } else {
            console.error('Error fetching survey status:', response);
        }
    };

    const downloadFile = async (surveyRunId, surveyName) => {
        var downloadResponse = await api.get(`/surveys/runs/${surveyRunId}/download/xlsx`, { responseType: "blob" });
        if (downloadResponse) {
          // Create blob link to download
          const url = window.URL.createObjectURL(
            new Blob([downloadResponse.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `${surveyName}.xlsx`,
          );
    
          // Append to html link element page
          document.body.appendChild(link);
    
          // Start download
          link.click();
    
          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
    }

    // actions represent the buttons and their functions showing in the table's "Actions" col
    const surveyActions = [
        {
          label: 'Pin',
          callback: async (rowData, apiRef) => {
            const isPinned = rowData.row.is_pinned;
            const surveyId = rowData.row.survey_id;

            try {
              const response = await api.put(`/surveys/${surveyId}`, {
                name: rowData.row.name,
                is_pinned: !isPinned
              });

              if (response.status === 200) {
                process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'Pin / Unpin');
                // update mergedSurveyList first
                setMergedSurveyList(prev =>
                  prev.map(survey => {
                    if (survey.survey_id === surveyId) {
                      return {...survey, is_pinned: !isPinned};
                    }
                    return survey;
                  })
                );

                if (isPinned) {
                  // pinned, so unpin
                  setPinnedRows(prev => ({
                    top: prev.top.filter(row => row.survey_id !== surveyId),
                    bottom: []
                  }));
                  setPinnedRowIds(prev => prev.filter(id => id !== surveyId));

                  // Create new row object to avoid reference issues
                  const updatedRow = {...rowData.row, is_pinned: false};
                  setUnpinnedRows(prev => [...prev, updatedRow]);
                } else {
                  // unpinned, so pin
                  const updatedRow = {...rowData.row, is_pinned: true};
                  setPinnedRows(prev => ({
                    top: [...prev.top, updatedRow],
                    bottom: []
                  }));
                  setPinnedRowIds(prev => [...prev, surveyId]);
                  setUnpinnedRows(prev => prev.filter(row => row.survey_id !== surveyId));
                }
              }
            } catch (error) {
              console.error('Error updating pin status:', error);
            }
          }
        },
        {
            label: 'Details',
            callback: (rowData) => {
                process.env.REACT_APP_ENV === 'staging' && console.log('displaying survey details:', rowData);
                const surveyId = rowData.row.survey_id;
                process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'View Details');
                navigate(`/edit-survey/${surveyId}?display=preview`);
            }
        },
        {
            label: 'View Results',
            callback: (rowData) => {
              process.env.REACT_APP_ENV === 'staging' && console.log('displaying survey results:', rowData);
              const surveyRunId = rowData.row.runs[0].survey_run_id;
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'View Results');
              navigate(`/result/${surveyRunId}`);
            }
        },
        {
            label: 'Edit',
            callback: (rowData) => {
                process.env.REACT_APP_ENV === 'staging' && console.log('editing survey:', rowData);
                const surveyId = rowData.row.survey_id;
                process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'Edit');
                navigate(`/edit-survey/${surveyId}`);
            }
        },
        {
            label: 'Download XLSX',
            callback: (rowData) => {
              process.env.REACT_APP_ENV === 'staging' && console.log('downloading xlsx:', rowData);
              const surveyRunId = rowData.row.runs[0].survey_run_id;
              const surveyName = rowData.row.name;
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'Download XLSX');
              downloadFile(surveyRunId, surveyName);
            }
        },
        {
            label: 'Copy',
            callback: async (rowData) => {
                process.env.REACT_APP_ENV === 'staging' && console.log('copying survey:', rowData);
                const surveyId = rowData.row.survey_id;
                // create a new survey object with the same data without all the id's,
                // and then save it to generate new ids
                const response = await api.get(`/surveys/${surveyId}`);
                const newSurvey = response.data;
                newSurvey.name = newSurvey.name + ' (copy)';
                newSurvey.runs = [];
                newSurvey.audience_name = newSurvey.audiences[0].audience;
                newSurvey.total_segments = newSurvey.audiences[0].segments.length;
                newSurvey.total_questions = newSurvey.questions.length;
                delete newSurvey.survey_id;
                delete newSurvey.created_at;
                delete newSurvey.updated_at;
                delete newSurvey.audiences[0].audience_id;
                delete newSurvey.audiences[0].created_at;
                newSurvey.audiences[0].segments.forEach(seg => {
                    delete seg.audience_id;
                    delete seg.audience_segment_id;
                });
                newSurvey.questions.forEach(ques => {
                    delete ques.survey_id;
                    delete ques.question_id;
                    // convert legacy types to lowercase
                    ques.question_type = ques.question_type.toLowerCase();
                });
                const createResponse = await api.post('/surveys/', {
                  name: newSurvey.name.substring(0, 255),
                });
                if (createResponse && createResponse.status === 200) {
                  process.env.REACT_APP_ENV === 'staging' && console.log('Survey created successfully');
                  process.env.REACT_APP_ENV === 'staging' && console.log('New survey:', createResponse.data);
                  const newSurveyId = createResponse.data.survey_id;
                  newSurvey.survey_id = newSurveyId;
                  newSurvey.created_at = new Date;
                  newSurvey.status = 'draft';

                  // create audiences
                  const createAudienceResponse = await api.post('/audiences/', {
                    survey_id: newSurveyId,
                    audience: newSurvey.audiences[0].audience,
                    audience_size: newSurvey.audiences[0].audience_size,
                    audience_chips: newSurvey.audiences[0].audience_chips,
                  });
                  if (createAudienceResponse && createAudienceResponse.status === 200) {
                    process.env.REACT_APP_ENV === 'staging' && console.log('Audience created successfully');
                    process.env.REACT_APP_ENV === 'staging' && console.log('New audience:', createAudienceResponse.data);
                    // create segments
                    const createSegmentResponse = await api.post(`/audiences/segments/bulk`, {
                      audience_id: createAudienceResponse.data.audience_id,
                      segments: newSurvey.audiences[0].segments,
                    });
                    if (createSegmentResponse && createSegmentResponse.status === 200) {
                      process.env.REACT_APP_ENV === 'staging' && console.log('Segment created successfully');
                      process.env.REACT_APP_ENV === 'staging' && console.log('New segment:', createSegmentResponse.data);
                      setHasNewSurvey(true);
                      setCopiedSurvey(newSurvey);
                      copiedSurveys.current.add(newSurvey);
                      process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', 'Copy');
                    } else {
                      console.error('Error creating segment:', createSegmentResponse);
                    }
                    // create questions
                    const createQuestionResponse = await api.post(`/questions/bulk`, {
                      survey_id: newSurveyId,
                      questions: newSurvey.questions,
                    });
                    if (createQuestionResponse && createQuestionResponse.status === 200) {
                      process.env.REACT_APP_ENV === 'staging' && console.log('Question created successfully');
                      process.env.REACT_APP_ENV === 'staging' && console.log('New question:', createQuestionResponse.data);
                    } else {
                      console.error('Error creating question:', createQuestionResponse);
                    }
                    // navigate(`/copy-survey/${surveyId}`);
                  } else {
                    console.error('Error creating audience:', createAudienceResponse);
                  }
                } else {
                  console.error('Error creating survey:', createResponse);
                }
            }
        },
        {
            label: 'Delete',
            callback: async (params) => {
                openDelete(params);
            },
        }
    ];

    return (
        <div className="dashboard">
            <div className='dashboard-content survey-list' style={{ width: 'auto' }}>
                <h1>Surveys</h1>
                { mergedSurveyList.length === 0 || !firstMergeSuccessful ? <Loading /> :
                <DynamicTable
                    tableData={unpinnedRows}
                    pinnedRows={pinnedRows}
                    updateTableData={setMergedSurveyList}
                    visibleColumns={['status', 'name', 'created_at', 'audience_name', 'total_segments', 'total_questions', 'runs[0].number_of_respondents']}
                    columnLabels={['Status', 'Name', 'Date', 'Audience', 'Segments', 'Questions', 'Respondents', 'Actions']}
                    smallColumns={['status', 'total_segments', 'total_questions']}
                    dynamicColumns={[{
                        column: 'runs[0].number_of_respondents',
                        dynamicComponent: (rowData) => {
                          if (rowData?.status === 'running') {
                            process.env.REACT_APP_ENV === 'staging' && console.log('running: ', rowData);
                            let counterText = '';
                            if (!rowData.statusData?.number_of_respondents || rowData.statusData?.number_of_respondents === 0) {
                              counterText = "Processing...";
                            } else if (rowData.statusData?.number_of_respondents === rowData.statusData?.total_respondents) {
                              counterText = "Finishing up...";
                            } else {
                              counterText = `${rowData.statusData ? rowData.statusData?.number_of_respondents : rowData.runs[0].number_of_respondents} /
                              ${rowData.statusData && rowData.statusData?.total_respondents > 0 ? rowData.statusData?.total_respondents : rowData.audience_size}`;
                            }
                            return (
                            <div className="prog-bar-wrapper">
                              <span style={{ width: '80%'}}>
                                {counterText} {counterText != "Processing..." && `(${Math.floor(rowData.statusData?.percent_complete)}%)` }
                                <LinearProgress variant="determinate" value={rowData.statusData ? rowData.statusData.percent_complete : 0} />
                              </span>
                              <Loading  sx={{ height: '20px', width: '20px'}}/>
                            </div>
                            )
                          } else if (rowData.statusData?.number_of_respondents > 0) {
                            return rowData.statusData?.number_of_respondents;
                          } else {
                            return rowData.runs?.length > 0 ? rowData.runs[0].number_of_respondents : rowData.audience_size;
                          }
                        }
                      },
                      {
                        column: 'status',
                        dynamicComponent: (rowData) => {
                          return (
                              <span className={`${rowData.status} status`}>{rowData.status === 'running' ? 'In Progress' : rowData.status}</span>
                          )
                        }
                      }]}
                    selectOnClick={false}
                    selectable={false}
                    draggable={false}
                    itemName="survey"
                    actions={surveyActions}
                    copiedSurvey={copiedSurvey}
                    setCopiedSurvey={setCopiedSurvey}
                    hasNewSurvey={hasNewSurvey}
                />
                    }
            </div>
            <DeleteDialog
              api={api}
              surveyList={surveyList}
              setSurveyList={setSurveyList}
              mergedSurveyList={mergedSurveyList}
              setMergedSurveyList={setMergedSurveyList}
              openDelete={openDelete}
              deleteParams={deleteParams}
              setDeleteParams={setDeleteParams}
              deleteDialogOpen={deleteDialogOpen}
              setDeleteDialogOpen={setDeleteDialogOpen}
              deletedSurveys={deletedSurveys}/>
        </div>
    );
};

export default SurveyList;
