import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
        <div className="footer-row">
            <span>&copy; 2025 Crowdwave</span>
        </div>
        <div className="footer-row">
            <Link
            onClick={() => {
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `TnC Link`);
            }}
            data-test-id="footer-tnc-link"
            to={'/terms-and-conditions'}
            target="_blank">Terms and Conditions</Link>
            <Link
            onClick={() => {
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `FAQ Link`);
            }}
            data-test-id="footer-faq-link"
            to={'https://crowdwave-ai.github.io/faq/'}
            target="_blank">FAQ</Link>
            <Link
            onClick={() => {
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `Contact Support Link`);
            }}
            data-test-id="footer-contact-link"
            to={'mailto:contact@crowdwave.ai'}>contact@crowdwave.ai</Link>
        </div>
    </div>
  );
}

export default Footer;