import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import { useApi } from '../../AuthProvider';

const WhatsNewModal = ({isWhatsNewModalOpen = false, setIsWhatsNewModalOpen, user }) => {
    const navigate = useNavigate();
    const api = useApi();

    React.useEffect(() => {
        if (window.location.pathname === '/' && user !== null && user !== undefined && user?.seen_latest_features === false) {
            //TODO this doesn't seem efficient
            setIsWhatsNewModalOpen(true);
        }
    }, [window.location.pathname, user]);

    return (
        <React.Fragment>
            <Dialog
                open={isWhatsNewModalOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{maxWidth:'900px', margin:'auto'}}
            >
                <DialogTitle id="alert-dialog-title">
                    <h3>{"Recent Updates"}</h3>
                </DialogTitle>
                <DialogContent className="whats-new-content">
                    <section>
                        <h3>Jan 31, 2025</h3>
                        <ul>
                            <li>
                                <strong>New Feature - Suggested Segment Traits:</strong>&nbsp;We’re excited to introduce Suggested Segment Traits, a new feature designed to make building audience segments faster and more intuitive. With this update, you’ll now see automatically generated suggestions for segment traits. This can help you generate ideas for new ways to segment your audience, save time by building complex segments with a simple click, and focus on refining and customizing segments instead of manually brainstorming criteria.
                            </li>
                            <li>
                                <strong>New Feature - Pin Surveys for Quick Access:</strong>&nbsp;You can now pin surveys to keep them at the top of your list! No more searching—simply pin your most important or frequently used surveys for easy access.
                            </li>
                            <li>
                                <strong>Question Enhancement - Multiple Rating Scales:</strong>&nbsp;We’ve upgraded the Numeric Rating question type to give you more flexibility! You can now choose from three rating scales. Easily select the scale that best fits your survey needs and capture more precise responses.
                                <ul>
                                    <li>1-5 (Classic, simple, and effective)</li>
                                    <li>1-7 (Great for nuanced feedback)</li>
                                    <li>1-10 (Ideal for detailed scoring)</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Enhanced Performance:</strong>&nbsp;We’ve released a series of improvements to improve the display of charts and to enhance the stability of the Crowdwave platform.
                            </li>
                        </ul>
                    </section>
                </DialogContent>
                <DialogActions>
                    <Button onClick={async () => {
                        if (!user?.seen_latest_features) {
                            // flip the seen_latest_features flag until the next release
                            const featuresResponse = await api.post(`/users/seen-latest-features`);
                            if (featuresResponse && featuresResponse.status !== 200) {
                                console.error('Error changing seen_latest_features');
                            }
                        }
                        setIsWhatsNewModalOpen(false);
                        }}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default WhatsNewModal;