import React, { useEffect } from 'react';
import { AppBar, Toolbar } from '@mui/material';
import logoWhite from '../../assets/Logo_White.svg';
import { Link, useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled, alpha } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import VideoModal from '../modals/VideoModal';
import WhatsNewModal from '../modals/WhatsNewModal';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Alert, AlertTitle } from '@mui/material';
import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: 'rgb(55, 65, 81)',
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
    ...theme.applyStyles('dark', {
      color: theme.palette.grey[300],
    }),
  },
}));

const HelpMenu = ({ setIsVideoModalOpen, setIsWhatsNewModalOpen }) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (callback) => {
    setAnchorEl(null);
    if (callback !== undefined && typeof callback === 'function') {
      callback();
    }
  };
  return (
    <div className="help-menu header-menu">
      <IconButton
        id="help-menu-toggle"
        size="small"
        aria-controls={open ? 'help-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        onClick={handleClick}
      >
        <HelpOutlineIcon sx={{ width: 32, height: 32, color: '#fff' }} />
      </IconButton>
      <StyledMenu
        id="help-menu"
        MenuListProps={{
          'aria-labelledby': 'help-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
            process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `What's New Modal`);
            handleClose(setIsWhatsNewModalOpen(true))
          }}>
          <a data-test-id="whats-new-menu-link">What's New in Crowdwave</a>
        </MenuItem>
        <MenuItem onClick={() => {
          process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `How To Video Modal`);
          handleClose(setIsVideoModalOpen(true))
          }}>
          <a data-test-id="how-to-menu-link">How To Use Crowdwave</a>
        </MenuItem>
        <MenuItem onClick={() => {handleClose(() => {
          document.getElementById('faq-menu-link').click();
          process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `FAQ Link`);
        })}}>
          <a id="faq-menu-link" data-test-id="faq-menu-link" href='https://crowdwave-ai.github.io/faq/' target="_blank">FAQ</a>
        </MenuItem>
        <MenuItem onClick={() => {handleClose(() => {
          document.getElementById('contact-menu-link').click();
          process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `Contact Support Link`);
        })}}>
          <a id="contact-menu-link" data-test-id="contact-menu-link" href="mailto:contact@crowdwave.ai" target="_blank">contact@crowdwave.ai</a>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const AccountMenu = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (context) => {
    if (context === 'logout') {
      console.log('logout clicked');
    }
    setAnchorEl(null);
  };
  return (
    <div className="account-menu header-menu">
      <IconButton
        id="account-menu-toggle"
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        onClick={handleClick}
      >
        <AccountCircleIcon sx={{ width: 32, height: 32, color: '#fff' }} />
      </IconButton>
      <StyledMenu
        id="account-menu"
        MenuListProps={{
          'aria-labelledby': 'account-menu-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem>
          <a
            data-test-id="logout-menu-link"
            onClick={() => {
              process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'User Action', `Logout`);
              logout({ logoutParams: { returnTo: window.location.origin } });
            }}>Logout</a>
        </MenuItem>
      </StyledMenu>
    </div>
  );
}

const Header = ({ isSurveyShown = false, user, setUser, isVideoModalOpen, setIsVideoModalOpen, isWhatsNewModalOpen, setIsWhatsNewModalOpen }) => {
  const [isMobileWarningOpen, setIsMobileWarningOpen] = React.useState(browser.getPlatformType() !== 'desktop');

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'staging') {
      console.log('user:');
      console.dir(user);
    }
  }, [user]);
  return (
    <>
    <AppBar
      position="static"
      className="header"
    >
      <VideoModal isVideoModalOpen={isVideoModalOpen} setIsVideoModalOpen={setIsVideoModalOpen}/>
      <WhatsNewModal user={user} setUser={setUser} isWhatsNewModalOpen={isWhatsNewModalOpen} setIsWhatsNewModalOpen={setIsWhatsNewModalOpen} />
      <Toolbar disableGutters>
        <div className="logo">
          <Link tabIndex={1} to={"/"} style={{ color: "white", textDecoration: "none" }}><img src={logoWhite} /></Link>
        </div>
        <ul className="nav-list">
          <li>
              <Link tabIndex={2} data-test-id="header-surveys-link" className={window.location.pathname === "/surveys" ? "active" : "" } to={`/surveys`}>
                Surveys
              </Link>
          </li>
          {!isSurveyShown && (
          <li>
            <Link
              tabIndex={3}
              data-test-id="create-survey-btn"
              className='btn-default'
              onClick={() => {
                process.env.REACT_APP_ENV === 'production' && window.clarity('set', 'Survey Action', `New Survey`);
              }}
              to={`/create-survey`}
              style={{ textDecoration: 'none', margin: '10px', justifyContent: 'center' }}>+ New survey</Link>
          </li>
          )}
        </ul>
        <HelpMenu
          setIsWhatsNewModalOpen={setIsWhatsNewModalOpen}
          setIsVideoModalOpen={setIsVideoModalOpen}
          isWhatsNewModalOpen={isWhatsNewModalOpen}
          isVideoModalOpen={isVideoModalOpen}
          sx={{ flexGrow: 0 }}/>
        <AccountMenu
          sx={{ flexGrow: 0 }}/>
      </Toolbar>
    </AppBar>
    { isMobileWarningOpen && (
      <Alert className="alert mobile-warning" severity="warning" sx={{ textAlign: 'center'}} onClose={() => setIsMobileWarningOpen(false)}>
        <AlertTitle>Crowdwave is most effectively used on a desktop or laptop.</AlertTitle>
        For optimal experience, please switch to a computer.
      </Alert>
    )}

    </>
  );
}

export default Header;